import React, { SyntheticEvent, useState } from 'react';
import { Button, Header, Icon, Modal, ModalActions, ModalContent, Popup } from 'semantic-ui-react';
import ProspectTracing from '../../../types/entities/ProspectTracing';
import { formatDateTime } from '../../../utils/datetimeUtils';

interface TracingDetailModalProps {
    id: string;
    tracing: ProspectTracing;
}

const TracingDetailModal = (props: TracingDetailModalProps) => {

    const [ openModal, setOpenModal ] = useState(false);

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="blue" icon size="mini" onClick={ (event) => handleOpenModal(event) }>
                                <Icon name="retweet" />
                            </Button>
                        }
                        content='Realizar seguimiento'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="phone" content="Realizar seguimiento" />
                <ModalContent>
                    <p>El seguimiento a este prospecto ya fue realizado o aun no es requerido. El siguiente seguimiento se hará el <b>{formatDateTime(props.tracing?.reminderAt)}</b>.</p>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="checkmark" /> Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default TracingDetailModal;
