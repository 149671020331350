import React from 'react';
import Navbar from './Navbar';
import { Container, Dimmer, Loader } from 'semantic-ui-react';
import { useAppContext } from '../hooks/AppProvider';

interface RootTemplateProps {
    children: React.ReactNode;
}

const RootTemplate = (props: RootTemplateProps) => {
    const { parentState } = useAppContext();
    return (
        <>
            <Navbar />
            <Dimmer active={parentState.loading} page>
                <Loader>Cargando...</Loader>
            </Dimmer>
            <Container className='container-custom'>
                {props.children}
            </Container>
        </>
    );
};

export default RootTemplate;
