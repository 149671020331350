import React from "react";
import { Container, Menu, Image } from "semantic-ui-react";
import logo from "../assets/img/logoblanco.svg";
import { useAuth } from "../hooks/AuthProvider";
import {
    USER_ROLE_ADMINISTRATOR,
    USER_ROLE_ADVISOR,
    USER_ROLE_INSTALLER,
    USER_ROLE_LOGISTIC,
    USER_ROLE_ORDERS,
    USER_ROLE_SOCIAL_MEDIA
} from "../utils/constants";

const Navbar = () => {
    const auth = useAuth();

    const handleLogout = () => {
        auth.logout();
    }

    return (
        <>
            <Menu fixed="top" size="large" inverted>
                <Container>

                    <Menu.Item as="a" href="/" header>
                        <Image size="tiny" src={logo} style={{ marginRight: "1.5em" }} />
                    </Menu.Item>

                    { auth.userData?.role === USER_ROLE_SOCIAL_MEDIA && (
                        <>
                            <Menu.Item as="a" href="/prospects">Prospectos</Menu.Item>
                        </>
                    ) }

                    { auth.userData?.role === USER_ROLE_ADVISOR && (
                        <>
                            <Menu.Item as="a" href="/assignments">Asignaciones</Menu.Item>
                            <Menu.Item as="a" href="/in-tracing">En seguimiento</Menu.Item>
                            <Menu.Item as="a" href="/completed">Completeados</Menu.Item>
                        </>
                    ) }

                    { auth.userData?.role === USER_ROLE_INSTALLER && (
                        <>
                            <Menu.Item as="a" href="/requests">Pedidos</Menu.Item>
                            <Menu.Item as="a" href="/orders">Ordenes</Menu.Item>
                        </>
                    ) }

                    { auth.userData?.role === USER_ROLE_LOGISTIC && (
                        <>
                            <Menu.Item as="a" href="/orders">Ordenes</Menu.Item>
                            <Menu.Item as="a" href="/reminders">Recordatorio anticipado</Menu.Item>
                            <Menu.Item as="a" href="/unfinished">Proyectos inconclusos</Menu.Item>
                            <Menu.Item as="a" href="/guarantees/adjustments">Ajustes</Menu.Item>
                        </>
                    ) }

                    { auth.userData?.role === USER_ROLE_ORDERS && (
                        <>
                            <Menu.Item as="a" href="/requests">Pedidos</Menu.Item>
                            <Menu.Item as="a" href="/orders">Ordenes</Menu.Item>
                            <Menu.Item as="a" href="/reminders">Recordatorio anticipado</Menu.Item>
                        </>
                    ) }

                    { auth.userData?.role === USER_ROLE_ADMINISTRATOR && (
                        <>
                            <Menu.Item as="a" href="/requests">Pedidos</Menu.Item>
                            <Menu.Item as="a" href="/orders">Ordenes</Menu.Item>
                            <Menu.Item as="a" href="/reminders">Recordatorio anticipado</Menu.Item>
                            <Menu.Item as="a" href="/guarantees">Garantias</Menu.Item>
                            <Menu.Item as="a" href="/products">Productos</Menu.Item>
                            <Menu.Item as="a" href="/users">Usuarios</Menu.Item>
                        </>
                    ) }

                    <Menu.Item onClick={ handleLogout } position="right">
                        Cerrar sesión
                    </Menu.Item>
                </Container>
            </Menu>
        </>
    );
};

export default Navbar;
