import React, { SyntheticEvent, useState } from 'react';
import { Button, Checkbox, FormField, Header, Icon, Input, Modal, ModalActions, ModalContent, Popup } from 'semantic-ui-react';
import Order from '../../../types/entities/Order';
import { toast } from 'react-toastify';
import { updateOrderStatusToProjectFinished } from '../../../services/orders.service';
import { useAppContext } from '../../../hooks/AppProvider';

interface Props {
    id: string | undefined;
    setOrder: (order: Order) => void;
}

const UpdateStatusToProjectFinishedModal = (props: Props) => {

    const { updateParentState } = useAppContext();

    const [ openModal, setOpenModal ] = useState(false);
    const [ confirmChecked, setConfirmChecked ] = useState(false);

    const [ file, setFile ] = useState<Blob | undefined>(undefined);
    const [ fileName, setFileName ] = useState("");
    const [ fullSettlement, setFullSettlement ] = useState("");

    const handleProjectFinished = async (event: SyntheticEvent, orderId: string | undefined) => {
        event.preventDefault();
        updateParentState({ loading: true });
        try {

            if (orderId === undefined) {
                throw new Error("El id del pedido no puede ser undefined.");
            }

            if (file === undefined) {
                throw new Error("Favor de seleccionar un comprobante de pago.");
            }

            const formData = new FormData();
            formData.append( "file", file, fileName );
            // clean remaining money after creating the order
            formData.append( "fullSettlement", fullSettlement?.replace(/[^0-9.,]/g, '').replace(/,/g, '') );

            const response = await updateOrderStatusToProjectFinished(orderId, formData);
            if (response?.status === 200) {
                props.setOrder(response?.data);
                toast.success("El pedido ha sido movido a proyecto terminado exitosamente.");
                setOpenModal(false);
            } else {
                throw new Error("Ha ocurrido un error al mover el pedido a proyecto terminado.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            updateParentState({ loading: false });
        }
    };

    const handleChangeAmount = (e: any, { name, value }: any) => {
        const newValue = value.replace(/[^0-9.,]/g, '').replace(/,/g, '');
        if (newValue.length <= 10) {
            setFullSettlement(formatMoney(newValue) ?? "");
        }
    };

    // this method receives a string of numbers and returns a formatted string
    const formatMoney = (value: string | undefined) => {
        if (!value) {
            return "";
        }
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const handlePaymentProof = async (event: any) => {
        event.preventDefault();
        try {
            const file = event.target.files[0];
            setFile(file);
            setFileName(file.name);
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="green" floated='right' icon onClick={ (event) => handleOpenModal(event) } >
                                Mover pedido a "Proyecto terminado"
                            </Button>
                        }
                        content='Mover pedido a "Proyecto terminado"'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="check square" content='Mover pedido a "Proyecto terminado"' />
                <ModalContent>
                    <p>
                        Recuerda que una vez que modifiques el estatus ya no se podra regresar al estatus
                        anterior y se enviaran las notificaciones correspondientes al cliente.
                    </p>

                    <div className="ui form">
                        <FormField
                            control={Input}
                            label={<label htmlFor="form-select-control-installer">Monto de liquidación</label>}
                            placeholder="Debe ser igual al saldo restante del pedido"
                            name="fullSettlement"
                            value={fullSettlement}
                            onChange={handleChangeAmount}
                            required
                        />

                        <FormField>
                            <label htmlFor="payment-proof">Adjuntar comprobante de pago</label>
                            <input id='payment-proof' type='file' accept="*" onChange={event => handlePaymentProof(event)} />
                        </FormField>

                        <FormField>
                            <Checkbox
                                label="Confirmo que quiero actualizar el estatus del pedido."
                                checked={confirmChecked}
                                onChange={ (event, data) => setConfirmChecked(confirmChecked ? false : true) }
                            />
                        </FormField>
                    </div>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => handleProjectFinished(event, props.id) } disabled={!confirmChecked}>
                        <Icon name="checkmark" /> Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default UpdateStatusToProjectFinishedModal;
