import React, { useState } from "react";
import { Label, MenuItem, Tab } from "semantic-ui-react";
import PageHeader from "../../components/PageHeader";
import InTracingRequiredListTab from "./tabs/InTracingRequiredListTab";
import InTracingAttendedListTab from "./tabs/InTracingAttendedListTab";
import RootTemplate from "../../components/RootTemplate";

const InTracingTabs = () => {

    const [ itemsRequired, setItemsRequired ] = useState(0);

    const panes = [
        { menuItem: (<MenuItem key='messages'>Requiren atención<Label color="red">{itemsRequired}</Label></MenuItem>), render: () => (
            <InTracingRequiredListTab setTotalItems={setItemsRequired} />
        ) },
        { menuItem: (<MenuItem key='messages'>Prospectos atendidos</MenuItem>), render: () => (
            <InTracingAttendedListTab />
        ) },
    ];

	return (
		<RootTemplate>
            <PageHeader title="En seguimiento" subtitle="Clientes potenciales" />
            <Tab panes={panes} />
		</RootTemplate>
	);
};

export default InTracingTabs;
