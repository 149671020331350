import React, { useEffect, useState } from "react";
import { Button, Card, Icon, Label } from "semantic-ui-react";
import { getReminders } from "../../services/requests.service";
import Order from "../../types/entities/Order";
import { ORDERS_STATUSES } from "../../utils/constants";
import LuxonDataTable from "../../components/LuxonDataTable";
import { daysPassedSince, formatDateTime } from "../../utils/datetimeUtils";
import InputSearch from "../../components/InputSearch";
import PageHeader from "../../components/PageHeader";
import { getOrdersUnfinished } from "../../services/orders.service";
import { toast } from "react-toastify";
import RootTemplate from "../../components/RootTemplate";

const OrdersUnfinishedList = () => {
	const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [search, setSearch] = useState("");

	const columns = [
        {
            name: "Folio",
            button: true,
            selector: (row: Order) => <Label horizontal>{`#${row.invoice}`}</Label>,
        },
        {
            name: "Fecha de compra",
            selector: (row: Order) => formatDateTime(row.orderDate),
        },
        {
            name: "Cliente",
            selector: (row: Order) => row.client?.person?.fullName ?? "",
        },
        {
            name: "Dias transcurridos",
            selector: (row: Order) => `${daysPassedSince(row.orderUnfinishedDate)} dias`,
        },
        {
            name: "Estatus",
            cell: (row: Order) => (
                <>
                    { row.status === null ? (
                        <Label color="olive">Pedido incompleto</Label>
                    ): (
                        <Label color={ORDERS_STATUSES[row.status].color}>{ORDERS_STATUSES[row.status].name}</Label>
                    )}
                </>
            ),
        },
        {
            name: "Acciones",
            button: true,
            cell: (row: Order) => (
                <>
                    <Button secondary icon size="mini" href={`orders/${row.id}/details`}>
                        <Icon name="search" />
                    </Button>
                </>
            ),
        },
    ];

	const fetchData = async (page: number, search?: string) => {
        setLoading(true);
        try {
			const response = await getOrdersUnfinished(perPage, page-1, "createdAt,asc", search);
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
        } catch (error) {
            toast.error("Error al obtener la informacion de pedidos inconclusos");
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);
        try {
			const response = await getReminders(newPerPage, page-1, "createdAt,asc", "");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
            setPerPage(newPerPage);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }        
    };

    useEffect(() => {
        fetchData(1);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchData(1, search);
        // eslint-disable-next-line
    }, [search]);

	return (
		<RootTemplate>
            <PageHeader title="Proyectos inconclusos" />
            <InputSearch icon="search" placeholder="Búsqueda por nombre de cliente..." setSearch={setSearch} />

            <Card fluid>
                <LuxonDataTable 
                    columns={columns}
                    data={data}
                    loading={loading}
                    totalRows={totalRows}
                    handlePageChange={handlePageChange}
                    handlePerRowsChange={handlePerRowsChange}
                />
            </Card>
		</RootTemplate>
	);
};

export default OrdersUnfinishedList;
