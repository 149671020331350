import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Button, Header, Icon, Modal, ModalActions, ModalContent, Popup, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { getOrderPayments } from '../../../services/orders.service';
import OrderPayment from '../../../types/entities/OrderPayment';
import { formatMoney } from '../../../utils/numberUtils';
import UploadOrderPaymentModal from './UploadOrderPaymentModal';
import { formatDateTime } from '../../../utils/datetimeUtils';

interface Props {
    orderId: string | undefined;
    buttonSize?: 'mini' | 'tiny' | 'small' | 'large' | 'big' | 'huge' | 'massive'
    create?: boolean
    totalAmount: number | undefined
}

const PaymentsListModal = ({ orderId, buttonSize, create, totalAmount }: Props) => {
    
    const [ loading, setLoading ] = useState(false);
    const [ openModal, setOpenModal ] = useState(false);

    const [ data, setData ] = useState<OrderPayment[]>([]);

    const fetchData = async (orderId: string) => {
        setLoading(true);
        try {
            const response = await getOrderPayments(orderId);
            setData(response?.data);
        } catch (error) {
            toast.error("Error al obtener el listado de pagos");
        } finally {
            setLoading(false);
        }
    };

    const setOrderPayment = (orderPayment: OrderPayment) => {
        data.push(orderPayment);
        setData(data);
    }

    const formatOrderType = (type: string) => {
        switch (type) {
            case 'ADVANCE':
                return 'Anticipo';
            case 'PARTIAL_SETTLEMENT':
                return 'Pago parcial - PPI';
            case 'FULL_SETTLEMENT':
                return 'Liquidación';
            default:
                return 'Abono';
        }
    }

    useEffect(() => {
        if (!orderId || !openModal) {
            return;
        }
        fetchData(orderId);
    }, [openModal, orderId]);

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button size={buttonSize} onClick={ (event) => handleOpenModal(event) }>
                                Comprobantes de pago
                            </Button>
                        }
                        content='Lista de pagos realizados'
                        position='bottom center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="dollar" content="Lista de pagos realizados" />
                <ModalContent>
                    <Table striped>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>Tipo</TableHeaderCell>
                                <TableHeaderCell>Monto</TableHeaderCell>
                                <TableHeaderCell>Fecha de pago</TableHeaderCell>
                                <TableHeaderCell>Comprobante de pago</TableHeaderCell>
                            </TableRow>
                        </TableHeader>

                        <TableBody>
                            { data?.map((payment: OrderPayment) => (
                                <TableRow key={payment?.id}>
                                    <TableCell>
                                        { formatOrderType(payment?.type) }
                                    </TableCell>
                                    <TableCell>
                                        ${ formatMoney(payment?.amount) }
                                    </TableCell>
                                    <TableCell>
                                        { formatDateTime(payment?.createdAt) }
                                    </TableCell>
                                    <TableCell>
                                        <a href={payment?.paymentProof} target="_blank" rel="noreferrer">Abrir</a>
                                    </TableCell>
                                </TableRow>
                            )) }
                        </TableBody>
                    </Table>
                    <p><strong>Saldo pendiente: ${ formatMoney((totalAmount || 0) - data?.reduce((total, payment) => total + payment?.amount, 0)) }</strong></p>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) } >
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <UploadOrderPaymentModal id={orderId} setOrderPayment={setOrderPayment} type='INSTALLMENT' disabled={loading || !create} />
                </ModalActions>
            </Modal>
        </>
    );
};

export default PaymentsListModal;
