import React from "react";
import { useAuth } from "../hooks/AuthProvider";
import { Route, Routes } from "react-router-dom";
import RequestsList from "../pages/orders/RequestsList";
import OrdersCreate from "../pages/orders/OrdersCreate";
import OrdersList from "../pages/orders/OrdersList";
import OrdersDetail from "../pages/orders/OrdersDetail";
import RemindersList from "../pages/orders/OrderRemindersList";
import ProductsList from "../pages/products/ProductsList";
import ProductsCreate from "../pages/products/ProductsCreate";
import Login from "../pages/auth/Login";
import OrderTracking from "../pages/orders/OrderTracking";
import ProspectsList from "../pages/prospects/ProspectsList";
import ProspectsCreate from "../pages/prospects/ProspectsCreate";
import AssignmentsList from "../pages/prospects/AssignmentsList";
import GuaranteeCreate from "../pages/guarantees/GuaranteeCreate";
import InTracingTabs from "../pages/prospects/InTracingTabs";
import CompletedList from "../pages/prospects/CompletedList";
import UsersForm from "../pages/users/UsersForm";
import ProspectsForm from "../pages/prospects/ProspectsForm";
import UsersList from "../pages/users/UsersList";
import OrdersForm from "../pages/orders/OrdersForm";
import { USER_ROLE_ADMINISTRATOR, USER_ROLE_ADVISOR, USER_ROLE_LOGISTIC, USER_ROLE_ORDERS, USER_ROLE_SOCIAL_MEDIA } from "../utils/constants";
import OrdersUnfinishedList from "../pages/orders/OrdersUnfinishedList";
import GuaranteesList from "../pages/guarantees/GuaranteesList";
import AdjustmentsList from "../pages/guarantees/AdjustmentsList";
import AdjustmentsForm from "../pages/guarantees/AdjustmentsForm";

interface Props {
    element: React.ReactNode;
}

const LuxonRoutes = ({ element }: Props) => {
    const auth = useAuth();
    return (
        <>
            <Routes>
                { !auth.accessToken && <Route path="/" element={<Login />} /> }
                <Route path="/login" element={<Login />} />
                <Route path="/orders-search" element={<OrderTracking />} />
                <Route element={element}>
                    { auth.userData?.role === USER_ROLE_ADMINISTRATOR && (
                        <>
                            <Route path="/" element={<RequestsList />} />
                            <Route path="/requests" element={<RequestsList />} />
                            <Route path="/requests/create" element={<OrdersCreate />} />
                            <Route path="/orders" element={<OrdersList />} />
                            <Route path="/orders/:orderId/form" element={<OrdersForm />} />
                            <Route path="/orders/:orderId/details" element={<OrdersDetail />} />
                            <Route path="/guarantee/:guaranteeId/form" element={<GuaranteeCreate />} />
                            <Route path="/reminders" element={<RemindersList />} />
                            <Route path="/guarantees" element={<GuaranteesList />} />
                            <Route path="/products" element={<ProductsList />} />
                            <Route path="/products/create" element={<ProductsCreate />} />
                            <Route path="/users" element={<UsersList />} />
                            <Route path="/users/:userId/form" element={<UsersForm />} />
                        </>
                    ) }

                    { auth.userData?.role === USER_ROLE_ORDERS && (
                        <>
                            <Route path="/" element={<RequestsList />} />
                            <Route path="/requests" element={<RequestsList />} />
                            <Route path="/requests/create" element={<OrdersCreate />} />
                            <Route path="/orders" element={<OrdersList />} />
                            <Route path="/orders/:orderId/form" element={<OrdersForm />} />
                            <Route path="/orders/:orderId/details" element={<OrdersDetail />} />
                            <Route path="/reminders" element={<RemindersList />} />
                        </>
                    ) }

                    { auth.userData?.role === USER_ROLE_LOGISTIC && (
                        <>
                            <Route path="/" element={<OrdersList />} />
                            <Route path="/orders" element={<OrdersList />} />
                            <Route path="/orders/:orderId/details" element={<OrdersDetail />} />
                            <Route path="/reminders" element={<RemindersList />} />
                            <Route path="/unfinished" element={<OrdersUnfinishedList />} />
                            <Route path="/guarantees/adjustments" element={<AdjustmentsList />} />
                            <Route path="/guarantees/adjustments/:guaranteeId/form" element={<AdjustmentsForm />} />
                        </>
                    ) }

                    { auth.userData?.role === USER_ROLE_SOCIAL_MEDIA && (
                        <>
                            <Route path="/" element={<ProspectsList />} />
                            <Route path="/prospects" element={<ProspectsList />} />
                            <Route path="/prospects/create" element={<ProspectsCreate />} />
                            <Route path="/prospects/:prospectId/form" element={<ProspectsForm />} />
                        </>
                    ) }

                        { auth.userData?.role === USER_ROLE_ADVISOR && (
                        <>
                            <Route path="/" element={<AssignmentsList />} />
                            <Route path="/assignments" element={<AssignmentsList />} />
                            <Route path="/in-tracing" element={<InTracingTabs />} />
                            <Route path="/completed" element={<CompletedList />} />
                            <Route path="/prospects/:prospectId/form" element={<ProspectsForm />} />
                        </>
                    ) }
                </Route>
            </Routes>
        </>
    );
};

export default LuxonRoutes;
