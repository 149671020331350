import React, { SyntheticEvent, useEffect, useState } from "react";
import { Card, Label, TabPane } from "semantic-ui-react";
import Prospect from "../../../types/entities/Prospect";
import { formatDateTime } from "../../../utils/datetimeUtils";
import { getInTracingRequired, markProspectTrackedById, updateProspectSaleStatusById } from "../../../services/prospects.service";
import InputSearch from "../../../components/InputSearch";
import LuxonDataTable from "../../../components/LuxonDataTable";
import { TRACING_STATUSES } from "../../../utils/constants";
import DoTracingModal from "../modals/DoTracingModal";
import SaleCompletedModal from "../modals/SaleCompletedModal";
import SaleNotCompletedModal from "../modals/SaleNotCompletedModal";
import { toast } from "react-toastify";

interface RequiredListTabProps {
    setTotalItems: (totalItems: number) => void;
}

const InTracingRequiredListTab = (props: RequiredListTabProps) => {

	const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ totalRows, setTotalRows ] = useState(0);
    const [ perPage, setPerPage ] = useState(10);

    const [ search, setSearch ] = useState("");

	const columns = [
        {
            name: "Fecha de visita",
            selector: (row: Prospect) => formatDateTime(row.appointment),
        },
        {
            name: "Nombre del prospecto",
            selector: (row: Prospect) => row.person?.fullName,
        },
        {
            name: "Producto",
            selector: (row: Prospect) => row.product.name,
        },
        {
            name: "Estatus",
            selector: (row: Prospect) => <Label color={TRACING_STATUSES[row.lastTracing?.traceNumber].color}>{TRACING_STATUSES[row.lastTracing?.traceNumber].name}</Label>,
        },
        {
            name: "Seguimiento",
            button: true,
            cell: (row: Prospect) => (
                <DoTracingModal id={row.id} currentTraceNumber={row.lastTracing?.traceNumber} submit={handleTracked} />    
            ),
        },
        {
            name: "Acciones",
            button: true,
            cell: (row: Prospect) => (
                <>
                    <SaleCompletedModal id={row.id} submit={handleSaleStatus} />
                    <SaleNotCompletedModal id={row.id} submit={handleSaleStatus} />
                </>
            ),
        },
    ];

	const fetchData = async (page: number, search?: string) => {
        setLoading(true);
        try {
			const response = await getInTracingRequired(perPage, page-1, search, "appointment,asc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
            props.setTotalItems(response?.data.totalElements);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);
        try {
			const response = await getInTracingRequired(newPerPage, page-1, "", "appointment,desc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
            setPerPage(newPerPage);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }        
    };

    useEffect(() => {
        fetchData(1, search);
        // eslint-disable-next-line
    }, [ , search]);

    const handleTracked = async (event: SyntheticEvent, prospectId: string | undefined, traceNumber: number, tracingNotes: string) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await markProspectTrackedById(prospectId, traceNumber, tracingNotes);
            if (response?.status === 201) {
                fetchData(1, search);
                toast.success("El seguimiento ha sido realizado con exito.");
            } else {
                throw new Error("Ha ocurrido un error al marcar como seguimiento realizado al prospecto.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSaleStatus = async (event: SyntheticEvent, prospectId: string | undefined, saleStatus: string) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await updateProspectSaleStatusById(prospectId, saleStatus);
            if (response?.status === 200) {
                fetchData(1, search);
                toast.success("El estatus del prospecto ha sido actualizado con exito.");
            } else {
                throw new Error("Ha ocurrido un error al actualizar el estatus del prospecto.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

	return (
        <TabPane>
            <InputSearch icon="search" placeholder="Búsqueda por nombre de prospecto..." setSearch={ setSearch } />

            <Card fluid>
                <LuxonDataTable
                    columns={columns}
                    data={data}
                    loading={loading}
                    totalRows={totalRows}
                    handlePageChange={handlePageChange}
                    handlePerRowsChange={handlePerRowsChange}
                />
            </Card>
        </TabPane>
	);
};

export default InTracingRequiredListTab;
